import {Button} from '@molecules';
import {FlexCol, FlexRow} from '@quarks';
import {useTheme} from 'styled-components';
import {GradientBar} from './GradientBar';
import {useControlOptions} from '@lib/logic';
import {Controllable} from '@lib/store';
import {pxToRem} from '@utils';

type Props = {
  control: Controllable;
  nodeId: string;
};

export const ControlOptions = ({control, nodeId}: Props) => {
  const options = useControlOptions(control, nodeId);
  const theme = useTheme();

  return (
    <FlexRow style={{gap: 20}}>
      <FlexCol
        gap={4}
        flex="1">
        {options.map(({title, action, disabled, isActive, isLoading}) => (
          <Button
            key={title}
            button="tertiary"
            loading={isLoading}
            disabled={disabled}
            style={{
              borderColor: isActive ? theme.button.secondary.border : undefined,
              height: pxToRem(48),
            }}
            onClick={action}>
            {title}
          </Button>
        ))}
      </FlexCol>

      <GradientBar type={control.type} />
    </FlexRow>
  );
};
