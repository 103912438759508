import {Icon} from '@atoms';
import {generateColorItemsWithGradients} from '@lib/logic';
import {FlexCol, FlexRow} from '@quarks';
import {pxToRem} from '@utils';
import {useEffect, useRef, useState} from 'react';
import {useTheme} from 'styled-components';

type Props = {
  type: 'temperature' | 'blinds';
};

export const GradientBar = ({type}: Props) => {
  const theme = useTheme();

  const ref = useRef(null);
  const [size, setSize] = useState({width: 0, height: 0});

  const totalRectangles = Math.floor(size.height / (RECT_HEIGHT + SPACING));

  const colors = generateColorItemsWithGradients(
    totalRectangles,
    type === 'temperature' ? theme.gradientBars.temperature.colors : theme.gradientBars.blinds.colors,
  );

  useEffect(() => {
    if (!ref.current) return;

    const observer = new ResizeObserver(([entry]) => {
      setSize({
        width: entry.contentRect.width,
        height: entry.contentRect.height,
      });
    });

    observer.observe(ref.current);

    return () => observer.disconnect();
  }, []);

  return (
    <FlexCol
      alignItems="center"
      style={{gap: 8, margin: '0px 3px 0px 0px'}}>
      <Icon
        icon={type === 'temperature' ? 'temperatureHot' : 'sunOpen'}
        size={pxToRem(18)}
      />

      <FlexCol
        flex="1"
        ref={ref}
        justifyContent="center"
        style={{resize: 'both', gap: SPACING}}>
        {colors.map((item, index) => (
          <FlexRow
            key={index}
            style={{
              background: item,
              height: RECT_HEIGHT,
              width: RECT_WIDTH,
              borderRadius: 4,
            }}
          />
        ))}
      </FlexCol>

      <Icon
        icon={type === 'temperature' ? 'temperatureCold' : 'sunClosed'}
        size={pxToRem(18)}
      />
    </FlexCol>
  );
};

const RECT_HEIGHT = 3;
const RECT_WIDTH = 16;
const SPACING = 3;
