import {config, useTransition} from 'react-spring';
import {HaNRoomInformationProps} from './types';

import {HaNModalHeader} from '../HaNModalHeader';
import {RoomInformationWrapper, StyledBody, StyledMapWrapper} from './styles';
import {useTranslation} from 'react-i18next';
import {useRoomScheduleStatus, useRoomSensorStatus, useAppSelector, useBreakPoint} from '@hooks';
import {getFloorHasMap} from '@lib/store';
import {
  HaNCardBottom,
  MeetingRoomActivities,
  MeetingRoomEquipment,
  MeetingRoomImage,
  MeetingRoomInfo,
  MeetingRoomMap,
} from '@organisms';
import {MeetingRoomData} from 'src/components/organisms/meetingRoom/MeetingRoomData';
import {FlexCol} from '@quarks';
import {HaNControlsSection} from '../HaNControlsSection';

/**
 * Displayed in modal.
 * Shows information about room.
 */

export const HaNRoomInformation = ({
  onBack,
  onClose,
  onBookNowCallback,
  room,
  page,
  setPage,
  duration = 0,
  endDateTime,
  startDateTime,
  showMapTile = 'mobile_only',
}: HaNRoomInformationProps) => {
  const {t} = useTranslation();
  const breakPoint = useBreakPoint();
  const scheduleStatus = useRoomScheduleStatus(room, startDateTime, endDateTime);
  const sensorStatus = useRoomSensorStatus(room);
  const floorHasMap = useAppSelector(getFloorHasMap(room.floorId));
  const showMap =
    (floorHasMap && breakPoint === 'small' && showMapTile === 'mobile_only') ||
    (floorHasMap && showMapTile === 'always');

  const transition = useTransition(page === 'roomInformation', {
    initial: {transform: 'translate3d(0%, 0, 0)'},
    from: {transform: 'translate3d(-100%, 0, 0)'},
    enter: {transform: 'translate3d(0%, 0, 0)'},
    leave: {transform: 'translate3d(-100%, 0, 0)'},
    config: {...config.stiff, clamp: true},
  });

  if (!room) return <></>;

  return transition(
    (style, item) =>
      item && (
        <RoomInformationWrapper style={style}>
          <HaNModalHeader
            onBack={onBack}
            onClose={onClose}
            title={t('screen:MeetingRoomDetails')}
          />
          <StyledBody as="section">
            <MeetingRoomInfo
              roomName={room.displayName}
              buildingName={room.buildingName}
              floorName={room.floorName}
              capacity={room.capacity}
            />
            <FlexCol>
              <MeetingRoomImage
                displayName={room.displayName}
                email={room.email}
                roomPictureHash={room.roomPictureHash}
                buildingId={room.buildingId}
              />
            </FlexCol>
            <MeetingRoomData
              sensorStatus={sensorStatus}
              scheduleStatus={scheduleStatus}
              onLearnMorePress={() => setPage('sensorInformation')}
            />
            <MeetingRoomActivities activities={room.activities} />
            <MeetingRoomEquipment equipment={room.equipment} />

            <HaNControlsSection nodeId={room.id} />

            {showMap && (
              <StyledMapWrapper>
                <MeetingRoomMap room={room} />
              </StyledMapWrapper>
            )}
          </StyledBody>
          <HaNCardBottom
            duration={duration}
            endDateTime={endDateTime}
            onBookNowCallback={onBookNowCallback}
            room={room}
            setPage={setPage}
            startDateTime={startDateTime}
          />
        </RoomInformationWrapper>
      ),
  );
};
