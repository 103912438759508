import {KeyboardEvent} from 'react';
import {useNavigate} from 'react-router-dom';
import {useTranslation} from 'react-i18next';

import {getAcceptedConnections, getConnectionsGroupedByWorkdayBuilding} from '@lib/store';
import {useAppSelector, useModal} from '@hooks';

import {FlexCol, Grid, P} from '@quarks';
import {Illustration} from '@atoms';
import {Button} from '@molecules';
import {ConnectionOverview, ModalPage} from '@organisms';
import {format, parseISO} from 'date-fns';

export const ConnectionsListModalPage = ({date}: {date: string}) => {
  const {closeModal} = useModal();
  const {t} = useTranslation();
  const navigate = useNavigate();

  const acceptedConnections = useAppSelector(getAcceptedConnections);
  // TODO : This selector is meant for the book a desk flow and should be optimized in the future.
  const connectionsGroupedByWorkdayBuilding = useAppSelector((state) =>
    getConnectionsGroupedByWorkdayBuilding(state, date),
  );

  const handleOnClick = () => navigate('/connections');

  const handleOnKeyDown = (event: KeyboardEvent<HTMLElement>) => {
    if (event.key === 'Enter') handleOnClick();
  };

  if (connectionsGroupedByWorkdayBuilding.length === 0) {
    return (
      <Grid
        gap={16}
        justifyItems="center">
        <Illustration
          illustration="connections"
          size="100px"
        />
        {acceptedConnections.length > 0 ? (
          <P textAlign="center">{t('connection:NoColleaguesInOfficeEmptyMessage')}</P>
        ) : (
          <FlexCol
            alignItems="center"
            gap={16}>
            <P fontWeight="bold">{t('connection:NoConnectionsAtAllTitle')}</P>
            <P textAlign="center">{t('connection:NoConnectionsAtAllMessages')}</P>
            <Button
              as="a"
              tabIndex={0}
              button="tertiary"
              onKeyDown={handleOnKeyDown}
              onClick={handleOnClick}>
              {t('connection:NoConnectionsAtAllButtonLabel')}
            </Button>
          </FlexCol>
        )}
      </Grid>
    );
  }

  const dateKey = format(parseISO(date), 'yyyy-MM-dd');

  return (
    <ModalPage
      onClose={closeModal}
      title={t('connection:YourConnections')}>
      <FlexCol gap={16}>
        <ConnectionOverview dateKey={dateKey} />
      </FlexCol>
    </ModalPage>
  );
};
