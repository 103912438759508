import {Icon} from '@atoms';
import {FlexCol, FlexRow, P} from '@quarks';
import {pxToRem} from '@utils';
import {useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useTheme} from 'styled-components';
import {ControlOptions} from './ControlOptions';
import {Controllable} from '@lib/store';

type Props = {
  item: Controllable;
  nodeId: string;
};

export const ControlItem = ({item, nodeId}: Props) => {
  const {t} = useTranslation();
  const theme = useTheme();

  const {displayName, type} = item;
  const icon = type === 'temperature' ? 'temperature' : 'blindsNew';
  const title = type === 'temperature' ? t('controls:Temperature') : t('controls:Blinds');

  const [isExpanded, setIsExpanded] = useState(false);

  return (
    <FlexCol
      background={theme.card.beige200.background}
      gap={8}
      style={{padding: '20px 24px 20px 24px'}}>
      <FlexRow
        onClick={() => setIsExpanded((prev) => !prev)}
        alignItems="center"
        gap={16}>
        <Icon
          icon={icon}
          size={pxToRem(24)}
        />

        <FlexCol flex="1">
          <P color={theme.text.color.subtitle}>{title}</P>
          <P>{displayName}</P>
        </FlexCol>

        <Icon
          icon={isExpanded ? 'caretUp' : 'caretDown'}
          size={pxToRem(24)}
        />
      </FlexRow>

      {isExpanded && (
        <ControlOptions
          control={item}
          nodeId={nodeId}
        />
      )}
    </FlexCol>
  );
};
