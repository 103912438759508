import {Illustration, Loader} from '@atoms';
import {useAppLinkLoader} from '@lib/logic';
import {FlexCol, H4, P} from '@quarks';
import {Canvas} from '@templates';
import {useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {useNavigate, useParams} from 'react-router-dom';
import {useHandleAppLink} from './useHandleAppLink';

export const AppLinks = () => {
  const {appLinkId} = useParams();
  const {t} = useTranslation();
  const navigate = useNavigate();

  const {appLink, status: appLinkLoadingStatus, error} = useAppLinkLoader(appLinkId ?? '');

  const handleAppLink = useHandleAppLink();

  useEffect(() => {
    if (appLinkLoadingStatus !== 'Loaded') return;

    const isHandled = handleAppLink(appLink);
    // with proper validation on BE this should never happen
    if (!isHandled) navigate('/');
  }, [appLink, appLinkLoadingStatus, handleAppLink, navigate]);

  if (appLinkLoadingStatus === 'NotLoaded' || appLinkLoadingStatus === 'Loading')
    return (
      <Canvas centerContent>
        <Loader />
      </Canvas>
    );

  if (appLinkLoadingStatus === 'Failed')
    return (
      <Canvas centerContent>
        <FlexCol
          alignItems="center"
          justifyContent="center"
          flex="auto"
          padding={32}
          gap={16}>
          <Illustration illustration="error" />
          <H4
            color="currentColor"
            textAlign="center">
            {error.title}
          </H4>
          <P
            color="currentColor"
            textAlign="center">
            {error.message}
          </P>
        </FlexCol>
      </Canvas>
    );
};
