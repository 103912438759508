import {DefaultTheme} from 'styled-components';
import {pxToRem} from '@utils';
import {COLOR_PALETTE} from '@constants';

export const defaultTheme: DefaultTheme = {
  background: {
    white: COLOR_PALETTE.white.a100,
    sand: COLOR_PALETTE.beige[100],
    dragAndDropAreaDragging: COLOR_PALETTE.mapiqBlack[50],
    overlay: COLOR_PALETTE.mapiqBlack.a15,
  },
  brand: COLOR_PALETTE.mapiqBlack[900],
  disabled: {
    background: COLOR_PALETTE.mapiqBlack[200],
    color: COLOR_PALETTE.mapiqBlack[200],
    colorHighContrast: COLOR_PALETTE.mapiqBlack[400],
  },
  border: {
    color: {
      dragAndDropActive: COLOR_PALETTE.black.a100,
      dragAndDropInactive: COLOR_PALETTE.mapiqBlack[100],
      sensorStatusUnknwon: COLOR_PALETTE.mapiqBlack[150],
    },
  },
  boxShadow: {
    secondary: `0px 2px 8px 0px ${COLOR_PALETTE.mapiqBlack.a03}`,
  },
  text: {
    color: {
      body: COLOR_PALETTE.mapiqBlack[800],
      heading: COLOR_PALETTE.mapiqBlack[800],
      subtitle: COLOR_PALETTE.mapiqBlack[500],
      error: COLOR_PALETTE.functional.red.default,
    },
    size: {
      xs: pxToRem(10),
      sm: pxToRem(13),
      md: pxToRem(15),
      lg: pxToRem(20),
    },
    weight: {
      regular: 400,
      bold: 600,
    },
  },
  link: {
    default: COLOR_PALETTE.collaborativeBlue[500],
    hover: COLOR_PALETTE.collaborativeBlue[600],
  },
  avatar: {
    border: COLOR_PALETTE.white.a100,
    colors: [
      `linear-gradient(254deg, ${COLOR_PALETTE.collaborativeBlue[500]}, ${COLOR_PALETTE.beige[200]}) no-repeat padding-box`,
      `linear-gradient(254deg, ${COLOR_PALETTE.energizingYellow[500]}, ${COLOR_PALETTE.beige[200]}) no-repeat padding-box`,
      `linear-gradient(254deg, ${COLOR_PALETTE.focusingBrown[500]}, ${COLOR_PALETTE.beige[200]}) no-repeat padding-box`,
      `linear-gradient(254deg, ${COLOR_PALETTE.inspiringOrange[500]}, ${COLOR_PALETTE.beige[200]}) no-repeat padding-box`,
      `linear-gradient(254deg, ${COLOR_PALETTE.creativePink[500]}, ${COLOR_PALETTE.beige[200]}) no-repeat padding-box`,
      `linear-gradient(254deg, ${COLOR_PALETTE.connectingGreen[500]}, ${COLOR_PALETTE.beige[200]}) no-repeat padding-box`,
    ],
    hover: [
      `linear-gradient(254deg, ${COLOR_PALETTE.collaborativeBlue[400]}, ${COLOR_PALETTE.beige[200]}) no-repeat padding-box`,
      `linear-gradient(254deg, ${COLOR_PALETTE.energizingYellow[400]}, ${COLOR_PALETTE.beige[200]}) no-repeat padding-box`,
      `linear-gradient(254deg, ${COLOR_PALETTE.focusingBrown[400]}, ${COLOR_PALETTE.beige[200]}) no-repeat padding-box`,
      `linear-gradient(254deg, ${COLOR_PALETTE.inspiringOrange[400]}, ${COLOR_PALETTE.beige[200]}) no-repeat padding-box`,
      `linear-gradient(254deg, ${COLOR_PALETTE.creativePink[400]}, ${COLOR_PALETTE.beige[200]}) no-repeat padding-box`,
      `linear-gradient(254deg, ${COLOR_PALETTE.connectingGreen[400]}, ${COLOR_PALETTE.beige[200]}) no-repeat padding-box`,
    ],
    unknown: COLOR_PALETTE.mapiqBlack[150],
  },
  button: {
    primary: {
      background: COLOR_PALETTE.mapiqBlack[800],
      border: COLOR_PALETTE.transparent.default,
      color: COLOR_PALETTE.white.a100,
      hover: {
        background: COLOR_PALETTE.black.a100,
        border: COLOR_PALETTE.transparent.default,
        color: COLOR_PALETTE.white.a100,
      },
      disabled: {
        background: COLOR_PALETTE.mapiqBlack[100],
        border: COLOR_PALETTE.transparent.default,
        color: COLOR_PALETTE.mapiqBlack[400],
      },
    },
    secondary: {
      background: COLOR_PALETTE.transparent.default,
      border: COLOR_PALETTE.mapiqBlack[800],
      color: COLOR_PALETTE.mapiqBlack[800],
      hover: {
        background: COLOR_PALETTE.transparent.default,
        border: COLOR_PALETTE.black.a100,
        color: COLOR_PALETTE.black.a100,
      },
      disabled: {
        background: COLOR_PALETTE.transparent.default,
        border: COLOR_PALETTE.mapiqBlack[400],
        color: COLOR_PALETTE.mapiqBlack[400],
      },
    },
    tertiary: {
      background: COLOR_PALETTE.mapiqBlack.a03,
      border: COLOR_PALETTE.transparent.default,
      color: COLOR_PALETTE.mapiqBlack[800],
      hover: {
        background: COLOR_PALETTE.mapiqBlack.a06,
        border: COLOR_PALETTE.transparent.default,
        color: COLOR_PALETTE.black.a100,
      },
      disabled: {
        background: COLOR_PALETTE.mapiqBlack.a03,
        border: COLOR_PALETTE.transparent.default,
        color: COLOR_PALETTE.mapiqBlack[400],
      },
    },
    'primary light': {
      background: COLOR_PALETTE.white.a100,
      border: COLOR_PALETTE.transparent.default,
      color: COLOR_PALETTE.mapiqBlack[800],
      hover: {
        background: COLOR_PALETTE.white.a90,
        border: COLOR_PALETTE.transparent.default,
        color: COLOR_PALETTE.mapiqBlack[800],
      },
      disabled: {
        background: COLOR_PALETTE.mapiqBlack[100],
        border: COLOR_PALETTE.transparent.default,
        color: COLOR_PALETTE.mapiqBlack[400],
      },
    },
    'secondary light': {
      background: COLOR_PALETTE.transparent.default,
      border: COLOR_PALETTE.white.a100,
      color: COLOR_PALETTE.white.a100,
      hover: {
        background: COLOR_PALETTE.transparent.default,
        border: COLOR_PALETTE.white.a90,
        color: COLOR_PALETTE.white.a90,
      },
      disabled: {
        background: COLOR_PALETTE.transparent.default,
        border: COLOR_PALETTE.mapiqBlack[400],
        color: COLOR_PALETTE.mapiqBlack[400],
      },
    },
    'tertiary light': {
      background: COLOR_PALETTE.white.a15,
      border: COLOR_PALETTE.transparent.default,
      color: COLOR_PALETTE.white.a100,
      hover: {
        background: COLOR_PALETTE.white.a20,
        border: COLOR_PALETTE.transparent.default,
        color: COLOR_PALETTE.white.a100,
      },
      disabled: {
        background: COLOR_PALETTE.mapiqBlack.a03,
        border: COLOR_PALETTE.transparent.default,
        color: COLOR_PALETTE.mapiqBlack[400],
      },
    },
    'primary destructive': {
      background: COLOR_PALETTE.functional.red.default,
      border: COLOR_PALETTE.transparent.default,
      color: COLOR_PALETTE.white.a100,
      hover: {
        background: COLOR_PALETTE.functional.red.hover,
        border: COLOR_PALETTE.transparent.default,
        color: COLOR_PALETTE.white.a100,
      },
      disabled: {
        background: COLOR_PALETTE.mapiqBlack[100],
        border: COLOR_PALETTE.transparent.default,
        color: COLOR_PALETTE.mapiqBlack[400],
      },
    },
    'secondary destructive': {
      background: COLOR_PALETTE.mapiqBlack.a03,
      border: COLOR_PALETTE.transparent.default,
      color: COLOR_PALETTE.functional.red.default,
      hover: {
        background: COLOR_PALETTE.mapiqBlack.a06,
        border: COLOR_PALETTE.transparent.default,
        color: COLOR_PALETTE.functional.red.hover,
      },
      disabled: {
        background: COLOR_PALETTE.mapiqBlack.a03,
        border: COLOR_PALETTE.transparent.default,
        color: COLOR_PALETTE.mapiqBlack[400],
      },
    },
    text: {
      background: COLOR_PALETTE.transparent.default,
      border: COLOR_PALETTE.transparent.default,
      color: COLOR_PALETTE.collaborativeBlue[500],
      hover: {
        background: COLOR_PALETTE.transparent.default,
        border: COLOR_PALETTE.transparent.default,
        color: COLOR_PALETTE.collaborativeBlue[700],
      },
      disabled: {
        background: COLOR_PALETTE.transparent.default,
        border: COLOR_PALETTE.transparent.default,
        color: COLOR_PALETTE.mapiqBlack[400],
      },
    },
  },
  card: {
    beige200: {
      background: COLOR_PALETTE.beige[200],
      border: COLOR_PALETTE.beige[200],
      color: COLOR_PALETTE.mapiqBlack[800],
      hover: {
        background: COLOR_PALETTE.beige[300],
        border: COLOR_PALETTE.beige[300],
        color: COLOR_PALETTE.mapiqBlack[800],
      },
    },
    beige500: {
      background: COLOR_PALETTE.beige[500],
      border: COLOR_PALETTE.beige[500],
      color: COLOR_PALETTE.mapiqBlack[800],
      hover: {
        background: COLOR_PALETTE.focusingBrown[200],
        border: COLOR_PALETTE.focusingBrown[200],
        color: COLOR_PALETTE.mapiqBlack[800],
      },
    },
    connectingGreen: {
      background: COLOR_PALETTE.connectingGreen[500],
      border: COLOR_PALETTE.connectingGreen[500],
      color: COLOR_PALETTE.white.a100,
      hover: {
        background: COLOR_PALETTE.connectingGreen[600],
        border: COLOR_PALETTE.connectingGreen[600],
        color: COLOR_PALETTE.white.a100,
      },
    },
    energizingYellow: {
      background: COLOR_PALETTE.energizingYellow[500],
      border: COLOR_PALETTE.energizingYellow[500],
      color: COLOR_PALETTE.mapiqBlack[800],
      hover: {
        background: COLOR_PALETTE.energizingYellow[550],
        border: COLOR_PALETTE.energizingYellow[550],
        color: COLOR_PALETTE.mapiqBlack[800],
      },
    },
    focusingBrown: {
      background: COLOR_PALETTE.focusingBrown[100],
      border: COLOR_PALETTE.focusingBrown[100],
      color: COLOR_PALETTE.mapiqBlack[800],
      hover: {
        background: COLOR_PALETTE.focusingBrown[200],
        border: COLOR_PALETTE.focusingBrown[200],
        color: COLOR_PALETTE.mapiqBlack[800],
      },
    },
    safeMauve: {
      background: COLOR_PALETTE.safeMauve[500],
      border: COLOR_PALETTE.safeMauve[500],
      color: COLOR_PALETTE.white.a100,
      hover: {
        background: COLOR_PALETTE.safeMauve[600],
        border: COLOR_PALETTE.safeMauve[600],
        color: COLOR_PALETTE.white.a100,
      },
    },
    transparent: {
      background: COLOR_PALETTE.transparent.default,
      border: COLOR_PALETTE.black.a10,
      color: COLOR_PALETTE.mapiqBlack[600],
      hover: {
        background: COLOR_PALETTE.black.a03,
        border: COLOR_PALETTE.black.a10,
        color: COLOR_PALETTE.black.a100,
      },
    },
    white: {
      background: COLOR_PALETTE.white.a100,
      border: COLOR_PALETTE.transparent.default,
      color: COLOR_PALETTE.mapiqBlack[800],
      hover: {
        background: COLOR_PALETTE.mapiqBlack[50],
        border: COLOR_PALETTE.transparent.default,
        color: COLOR_PALETTE.mapiqBlack[800],
      },
    },
    opaque: {
      background: COLOR_PALETTE.black.a03,
      border: COLOR_PALETTE.transparent.default,
      color: COLOR_PALETTE.black.a100,
      hover: {
        background: COLOR_PALETTE.black.a06,
        border: COLOR_PALETTE.transparent.default,
        color: COLOR_PALETTE.black.a100,
      },
    },
    red: {
      background: COLOR_PALETTE.inspiringOrange[600],
      border: COLOR_PALETTE.transparent.default,
      color: COLOR_PALETTE.white.a100,
      hover: {
        background: COLOR_PALETTE.inspiringOrange[700],
        border: COLOR_PALETTE.transparent.default,
        color: COLOR_PALETTE.white.a100,
      },
    },
    mapiqBlack: {
      background: COLOR_PALETTE.mapiqBlack[75],
      border: COLOR_PALETTE.mapiqBlack[75],
      color: COLOR_PALETTE.mapiqBlack[800],
      hover: {
        background: COLOR_PALETTE.mapiqBlack[150],
        border: COLOR_PALETTE.mapiqBlack[150],
        color: COLOR_PALETTE.mapiqBlack[800],
      },
    },
  },
  divider: {
    white: COLOR_PALETTE.white.a100,
    grey: COLOR_PALETTE.mapiqBlack[150],
    sand: COLOR_PALETTE.mapiqBlack[150],
  },
  occupancy: {
    background: COLOR_PALETTE.mapiqBlack[150],
    disabled: COLOR_PALETTE.mapiqBlack[300],
    green: COLOR_PALETTE.functional.green.default,
    yellow: COLOR_PALETTE.energizingYellow[500],
    orange: COLOR_PALETTE.functional.orange.default,
    red: COLOR_PALETTE.functional.red.default,
    sensorAway: COLOR_PALETTE.mapiqBlack[50],
  },
  input: {
    background: COLOR_PALETTE.white.a100,
    border: {
      default: COLOR_PALETTE.mapiqBlack[150],
      checked: COLOR_PALETTE.mapiqBlack[800],
      transparent: COLOR_PALETTE.transparent.default,
    },
    color: COLOR_PALETTE.mapiqBlack[800],
    placeholder: COLOR_PALETTE.mapiqBlack[400],
    hover: {
      background: COLOR_PALETTE.mapiqBlack[50],
      border: COLOR_PALETTE.mapiqBlack[800],
      color: COLOR_PALETTE.mapiqBlack[800],
    },
    invalid: {
      background: COLOR_PALETTE.mapiqBlack[50],
      border: COLOR_PALETTE.functional.red.default,
      color: COLOR_PALETTE.mapiqBlack[800],
    },
    select: {
      background: COLOR_PALETTE.white.a100,
    },
    toggle: {
      background: COLOR_PALETTE.mapiqBlack[400],
      checked: COLOR_PALETTE.functional.green.default,
      handle: COLOR_PALETTE.white.a100,
    },
    option: {
      background: COLOR_PALETTE.white.a100,
      color: COLOR_PALETTE.mapiqBlack[800],
      hover: {
        background: COLOR_PALETTE.mapiqBlack[100],
        color: COLOR_PALETTE.mapiqBlack[800],
      },
      selected: {
        background: COLOR_PALETTE.mapiqBlack[800],
        color: COLOR_PALETTE.white.a100,
      },
    },
  },
  label: {
    beige: {
      background: COLOR_PALETTE.beige[200],
      color: COLOR_PALETTE.mapiqBlack[800],
      hover: {
        background: COLOR_PALETTE.beige[300],
        color: COLOR_PALETTE.mapiqBlack[800],
      },
    },
    connectingGreen: {
      background: COLOR_PALETTE.functional.green.default,
      color: COLOR_PALETTE.white.a100,
      hover: {
        background: COLOR_PALETTE.functional.green.hover,
        color: COLOR_PALETTE.white.a100,
      },
    },
  },
  toast: {
    success: {
      background: COLOR_PALETTE.mapiqBlack[800],
      color: COLOR_PALETTE.white.a100,
    },
    warning: {
      background: COLOR_PALETTE.functional.orange.default,
      color: COLOR_PALETTE.white.a100,
    },
    error: {
      background: COLOR_PALETTE.functional.red.default,
      color: COLOR_PALETTE.white.a100,
    },
  },
  icon: {
    active: COLOR_PALETTE.mapiqBlack[800],
    inactive: COLOR_PALETTE.mapiqBlack[400],
    duotone: {
      color: COLOR_PALETTE.white.a100,
    },
  },
  validation: {
    iconColor: COLOR_PALETTE.white.a100,
    error: COLOR_PALETTE.functional.red.default,
    success: COLOR_PALETTE.functional.green.default,
    unknown: COLOR_PALETTE.mapiqBlack[500],
    warning: COLOR_PALETTE.functional.orange.default,
    notification: COLOR_PALETTE.collaborativeBlue[500],
    card: {
      error: {
        iconBackground: COLOR_PALETTE.functional.red.default,
        background: COLOR_PALETTE.functional.red.default,
      },
      warning: {
        iconBackground: COLOR_PALETTE.functional.orange.default,
        background: COLOR_PALETTE.functional.orange.default,
      },
    },
  },
  mapiqLogo: {
    light: COLOR_PALETTE.white.a100,
    dark: COLOR_PALETTE.mapiqBlack[800],
  },

  gradientBars: {
    temperature: {
      colors: [
        COLOR_PALETTE.inspiringOrange[500],
        COLOR_PALETTE.inspiringOrange[300],
        COLOR_PALETTE.inspiringOrange[200],
        COLOR_PALETTE.collaborativeBlue[200],
        COLOR_PALETTE.collaborativeBlue[300],
        COLOR_PALETTE.collaborativeBlue[500],
      ],
    },
    blinds: {
      colors: [
        COLOR_PALETTE.energizingYellow[500],
        COLOR_PALETTE.energizingYellow[300],
        COLOR_PALETTE.energizingYellow[200],
        COLOR_PALETTE.safeMauve[200],
        COLOR_PALETTE.safeMauve[300],
        COLOR_PALETTE.safeMauve[500],
      ],
    },
  },
};
