import {useAppSelector, useBreakPoint, useModal} from '@hooks';
import {Tile} from '@organisms';
import {FlexCol, H3, H4, Subtitle} from '@quarks';
import {Avatar} from '@molecules';
import {Icon} from '@atoms';
import {ConnectionsLoader} from './ConnectionsLoader';
import {ConnectionUserDto, WorkspaceReservationDto, trackEvent} from '@lib/infrastructure';
import {cn} from '@utils';
import {ConnectionOnMapModal} from './ConnectionOnMapModal';
import {NoConnections, NoOneThere} from './EmptyState';
import {getIsExperienceImprovementsEnabled, useRelevantConnections} from '@lib/store';
import {useTranslation} from 'react-i18next';
import {Fragment, PropsWithChildren} from 'react';

export const ConnectionOverview = (props: {dateKey: string; asBigPage?: boolean; hideTitle?: boolean}) => {
  const isExperienceImprovementsEnabled = useAppSelector(getIsExperienceImprovementsEnabled);
  const {t} = useTranslation();
  const bp = useBreakPoint();
  const {setBigPages, openModal, addPages} = useModal();

  const {status, connections, connectionInOfficeByBuilding} = useRelevantConnections(props.dateKey);

  const showConnectionOnMap = (user: ConnectionUserDto, workspace: WorkspaceReservationDto) => {
    const mapView = (
      <ConnectionOnMapModal
        user={user}
        workspace={workspace}
      />
    );

    trackEvent('Home_ConnectionsOverview__ExpandMap');

    addPages([mapView]);
    if (props.asBigPage) setBigPages([mapView]);
    openModal();
  };

  if (status === 'Loading') return <ConnectionsLoader />;

  return (
    <FlexCol
      gap={16}
      className={cn({'mb-8': !isExperienceImprovementsEnabled, 'w-full': isExperienceImprovementsEnabled})}>
      {!props.hideTitle && <H3 className="pl-0">{t('screen:AllConnections')}</H3>}

      {status === 'UserHasNoConnections' ? (
        <NoConnections />
      ) : status === 'Done' ? (
        connectionInOfficeByBuilding.map(({buildingName, data}) => (
          <Fragment key={buildingName}>
            <H4 className="pl-0">{buildingName}</H4>
            <div className={cn('grid full-w gap-6 grid-cols-1', {'grid-cols-2': bp === 'large'})}>
              {data.map(({user, workspace}) => (
                <div
                  className="flex w-full gap-2 items-center"
                  key={user.id}>
                  <Avatar
                    className="flex-none"
                    shouldShowCheckIn
                    user={user}
                  />
                  <div className="overflow-hidden mr-auto">
                    <p className="truncate">{user.name}</p>
                    {workspace && workspace.floorHasMap ? (
                      <Subtitle className="truncate">{`${workspace.floorName}, ${workspace.areaName}`}</Subtitle>
                    ) : null}
                  </div>
                  {workspace ? (
                    <button
                      className="flex-none"
                      aria-label={t('ShowOnMap')}
                      onClick={() => {
                        showConnectionOnMap(user, workspace);
                      }}>
                      <Icon
                        icon="map"
                        size="20px"
                        color="#767676"
                      />
                    </button>
                  ) : null}
                </div>
              ))}
            </div>
          </Fragment>
        ))
      ) : (
        <NoOneThere />
      )}
    </FlexCol>
  );
};
