import {ContextsProps} from './types';
import {DialogProvider, ModalProvider, ThemeProvider, SidePanelProvider} from '@contexts';
import {FiltersProvider, GlobalImageCacheProvider} from '@lib/logic';
import {IntentsProvider} from './Intents';

export const Contexts = ({children}: ContextsProps) => {
  return (
    <ThemeProvider>
      <GlobalImageCacheProvider>
        <DialogProvider>
          <ModalProvider>
            <FiltersProvider>
              <IntentsProvider>
                <SidePanelProvider>{children}</SidePanelProvider>
              </IntentsProvider>
            </FiltersProvider>
          </ModalProvider>
        </DialogProvider>
      </GlobalImageCacheProvider>
    </ThemeProvider>
  );
};
