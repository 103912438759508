import {useRef, useEffect, useCallback} from 'react';

export const useVerticalScrollToElement = (
  autoScrollDelay = 0,
  prerequisitesLoaded = true,
  autoScroll = false,
  autoScrollCallback?: () => void,
) => {
  const componentRef = useRef<HTMLDivElement | null>(null);

  const scrollToComponent = useCallback(() => {
    if (componentRef.current) {
      componentRef.current?.scrollIntoView({behavior: 'smooth', block: 'start'});
    }
  }, []);

  useEffect(() => {
    if (!autoScroll || !prerequisitesLoaded) return;

    let timeout: NodeJS.Timeout | null = null;

    if (componentRef.current) {
      timeout = setTimeout(() => {
        scrollToComponent();
        autoScrollCallback?.();
      }, autoScrollDelay);
    }

    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
    };
  }, [autoScroll, autoScrollDelay, prerequisitesLoaded, autoScrollCallback, scrollToComponent]);

  return {componentRef, scrollToComponent};
};
