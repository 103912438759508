import {useTranslation} from 'react-i18next';
import {HaNModalHeader} from '../HaNModalHeader';
import {FacilityInformationWrapper} from './style';
import {FacilityResource} from '@lib/store/src/data/buildingResources';
import {StyledBody} from '../HaNRoomInformation/styles';
import {FlexCol, FlexRow, H1, P} from '@quarks';
import {StyledModalCellItemIcon} from '../HaNWorkspaceCell/styles';
import {HaNControlsSection} from '../HaNControlsSection';

type Props = {
  onClose: () => void;
  facility: FacilityResource;
};

export const HaNFacilityModal = ({onClose, facility}: Props) => {
  const {name, floorName, id} = facility;
  const {t} = useTranslation();

  return (
    <FacilityInformationWrapper>
      <HaNModalHeader
        title={t('Facility')}
        onClose={onClose}
      />
      <StyledBody as="section">
        <FlexCol gap={8}>
          <H1 as="h2">{name}</H1>
          <P>{floorName}</P>
        </FlexCol>
        <FlexRow
          gap={8}
          justifyContent="flex-start">
          <StyledModalCellItemIcon
            // the icon is not being used if there is iconUrl
            icon={'toilet'}
            iconUrl={facility.data?.facilityType.lightIconUrl}
          />
          <FlexCol
            justifyContent="center"
            gap={2}>
            <P>{facility.data.facilityType.displayName}</P>
          </FlexCol>
        </FlexRow>

        <HaNControlsSection nodeId={id} />
      </StyledBody>
    </FacilityInformationWrapper>
  );
};
