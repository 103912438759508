import {AppLinkDto} from '@lib/infrastructure';
import {getNavigationActionFromAppLink, NavigationAction, QueryParamConstants} from '@lib/logic';
import {useCallback} from 'react';
import {useNavigate} from 'react-router-dom';

export const useHandleAppLink = () => {
  const navigate = useNavigate();

  // returns true if handled
  return useCallback(
    (link: AppLinkDto): boolean => {
      const {intent, location} = link;
      const {buildingId, nodeName, nodeId} = location;

      const navigationAction = getNavigationActionFromAppLink(link);

      switch (navigationAction) {
        case NavigationAction.Explore_Controls:
          const params = new URLSearchParams({
            [QueryParamConstants.hereAndNow.explore.buildingId]: buildingId,
            [QueryParamConstants.hereAndNow.explore.search]: nodeName,
            [QueryParamConstants.hereAndNow.explore.resourceId]: nodeId,
            [QueryParamConstants.intent]: intent,
          });
          navigate(`/now/explore?${params.toString()}`);
          return true;

        case NavigationAction.Unknown:
          return false;
      }
    },
    [navigate],
  );
};
