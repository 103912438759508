import {IntentType} from '@lib/infrastructure';
import {createContext, ReactNode, useCallback, useState} from 'react';

type IntentsProviderProps = {
  children: ReactNode;
};

type IntentState = {intent: IntentType; isHandled: boolean};
type IntentsContextProps = {
  linkedIntent: IntentState | null;
  claimHandled: () => void;
  setIntentIfNotSet: (intent: IntentType | null) => void;
};

export const IntentsContext = createContext<IntentsContextProps>(null!);

export const IntentsProvider = ({children}: IntentsProviderProps) => {
  const [linkedIntent, setLinkedIntent] = useState<IntentState | null>(null);

  const claimHandled = useCallback(() => {
    setLinkedIntent((prevValue) => (prevValue === null ? null : {...prevValue, isHandled: true}));
  }, []);

  const setIntentIfNotSet = useCallback(
    (intent: IntentType | null) => {
      if (intent === null || linkedIntent !== null) return;

      setLinkedIntent({intent, isHandled: false});
    },
    [linkedIntent],
  );

  return (
    <IntentsContext.Provider
      value={{
        linkedIntent,
        claimHandled,
        setIntentIfNotSet,
      }}>
      {children}
    </IntentsContext.Provider>
  );
};
