import {Loader} from '@atoms';
import {useControlLoader} from '@lib/logic';
import {useOverridableFeatureFlags} from '@lib/store';
import {FlexCol, H2} from '@quarks';
import {useTranslation} from 'react-i18next';
import {ControlItem} from './ControlItem';
import {useIntents, useVerticalScrollToElement} from '@hooks';
import {IntentType} from '@lib/infrastructure';
import {useMemo, useState} from 'react';
import {StyledButton} from './styles';

type Props = {
  nodeId: string;
};

export const HaNControlsSection = ({nodeId}: Props) => {
  const {t} = useTranslation();
  const {Controls} = useOverridableFeatureFlags();

  const {controls, status} = useControlLoader(nodeId, true, 30);
  const firstFourControls = useMemo(() => controls.slice(0, 4), [controls]);
  const isLoading = status === 'NotLoaded' || status === 'Loading';

  const {linkedIntent, claimHandled} = useIntents();
  const shouldAutoScroll =
    linkedIntent !== null && linkedIntent.intent === IntentType.ControlBlindsOrTemperature && !linkedIntent.isHandled;

  const {componentRef} = useVerticalScrollToElement(300, !isLoading, shouldAutoScroll, claimHandled);

  const [showMore, setShowMore] = useState(false);
  const controlsToShow = showMore ? controls : firstFourControls;

  if (!Controls || controls.length === 0) return undefined;

  return (
    <FlexCol gap={16}>
      <H2 as="h3">{t('controls:Control')}</H2>

      <FlexCol>
        {isLoading ? (
          <FlexCol alignItems="center">
            <Loader />
          </FlexCol>
        ) : (
          <FlexCol>
            <FlexCol
              ref={componentRef}
              gap={2}
              borderRadius={8}
              overflow="hidden">
              {controlsToShow.map((control) => (
                <ControlItem
                  key={control.id}
                  item={control}
                  nodeId={nodeId}
                />
              ))}
            </FlexCol>
            {controls.length > 4 ? (
              <StyledButton
                button="text"
                onClick={() => setShowMore((current) => !current)}>
                {t(showMore ? 'ShowLess' : 'ShowAll')}
              </StyledButton>
            ) : null}
          </FlexCol>
        )}
      </FlexCol>
    </FlexCol>
  );
};
